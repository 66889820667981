import $ from 'jquery';
import Cookies from 'js-cookie';
import Carousel from 'bootstrap/js/src/carousel';
import {getDevType} from '@utils/commonUtils';
import RecentlyViewedItems from '../components/RecentlyViewedItems';
import abTestService from '../services/abTestService';
import {initCountDown} from '../helpers/promotionHelpers';
import HeaderSearch from '../components/HeaderSearch';
import Modals from '../components/modals';

class Homepage {
	constructor() {
		this.init();
	}

	init() {
		this.ticketInformationBanner();
		new RecentlyViewedItems();
		initCountDown();

		if (!$('.homepageArrangementsListContainer').length) {
			const devType = getDevType();

			if (devType === 'tablet' || devType === 'desktop') {
				this.initCarousels();
			}
		}
		
		requestAnimationFrame(() => {
			new HeaderSearch();
			this.initEvents();
			new Modals();
			
			if (abTestService.isAnyVariationActive(['increase_user_trust_no', 'increase_user_trust_yes'])) {
				abTestService.sendEvent('LA_DK_v1_user_trust_level', 'homepageOpen');
			}
		});
	}

	initCarousels() {
		const carousels = document.querySelectorAll('.carousel');

		carousels.forEach((carousel) => {
			new Carousel(carousel, {
				'interval': false
			});
		});
	}

	ticketInformationBanner() {
		const ticketInformationBanner      = $('.ticketInformationBanner'),
			  hideOfficialTicketOnHomePage = Cookies.get('hideTicketInformationBannerOnHomePage') || false;

		if (!hideOfficialTicketOnHomePage) {
			ticketInformationBanner.show();
		}

		ticketInformationBanner
			.on('click', '.close', () => {
				$('.ticketInformationBanner, .ticketInformationBannerOpenedBackground').remove();
				Cookies.set(
					'hideTicketInformationBannerOnHomePage',
					false,
					{
						'expires': 180
					}
				);
				$('body').removeClass('ticketInformationBannerOpened');
			})
			.on('click', '.show.showBtn', () => {
				ticketInformationBanner.addClass('opened');
				$('.show.showBtn').hide();
			});
	}

	initEvents() {
		$('.giftcard .centeredCtaButton').on('click', () => {
			dataLayer.push({
				'event'        : 'Book Gift Card CTA',
				'eventCategory': 'User-Action',
				'eventAction'  : 'Event_Book_Gift_Card_CTA',
				'eventLabel'   : 'Gift-Card-Funnel'
			});
		});
		
		$('.offers').find('.price.has-tip.tip-img, .topOffersHalfPayOption .has-tip').on('click', false);
		
		if (abTestService.isAnyVariationActive(['moveHomepageReviewsUp_no', 'moveHomepageReviewsUp_yes'])) {
			$('.giftcard .giftCardCta').one(
				'mousedown',
				() => abTestService.sendEvent('FBR_v1_moveHomepageReviewsUp', 'linkClicked')
			);
		}
	}

}

new Homepage();